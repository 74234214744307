<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="user-center">
        <div class="main-view">
          <div class="user-maininfo">
            <div class="user-head">
              <div class="user-img-container">
                <span class="user-img">
                  <img class="head-photo" :src="profile" alt="会员头像" />
                </span>
              </div>

              <div class="user-phone">
                <span>{{myName}}</span>
              </div>
              <div class="action-container">
                <a href="javascript:void(0)" class="action set-personal" @click="iSettingsShow">
                  <span class="icon aicon-logout">
                    <i class="el-icon-setting"></i>
                  </span>&nbsp;&nbsp;个人信息
                </a>
                <a href="javascript:void(0)" class="action logout" @click="logout">
                  <span class="icon aicon-logout">
                    <i class="el-icon-d-arrow-right"></i>
                  </span>&nbsp;&nbsp;退出登录
                </a>
              </div>
              <!-- <ul class="user-action clearfix">
                <li class="fl user-action-item">
                  <a href="javascript:void(0)" style="cursor:default">
                    <span class="aicon aicon-membership"></span>
                    <p class="desc-text">业绩积分</p>
                    <p class="desc-text-tips">1019</p>
                  </a>
                </li>
                <li class="fl user-action-item invisible">
                  <a href="javascript:void(0)">
                    <span class="aicon aicon-coupons"></span>
                    <p class="desc-text">我的申请</p>
                    <p class="desc-text-tips"></p>
                  </a>
                </li>
                <li class="fl user-action-item">
                  <a href="javascript:void(0)" class="address-book">
                    <span class="aicon aicon-address-book"></span>
                    <p class="desc-text">其他</p>
                  </a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-model="iSettingsShowModal"
      width="560"
      :mask-closable="false"
      @on-cancel="modal_cancel"
      key="rewr"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>个人信息</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="sub-content-wrapper">
          <div class="sub-content-title">信息更改</div>
          <div class="table-wrapper">
            <table>
              <tr>
                <td>真实姓名：</td>
                <td>
                  <input type="text" v-model="user.name" autocomplete="off" />
                </td>
              </tr>
              <tr>
                <td>电子邮箱：</td>
                <td>
                  <input type="text" v-model="user.email" autocomplete="off" />
                </td>
              </tr>
              <tr>
                <td>账户状态：</td>
                <td>
                  <span class="info-span">{{user.stat}}</span>
                </td>
              </tr>
              <tr>
                <td>注册时间：</td>
                <td>
                  <span class="info-span">{{user.reg_time}}</span>
                </td>
              </tr>
              <tr>
                <td>担任职务：</td>
                <td>
                  <span class="info-span role-span" v-for="r in user.role" :key="r.id">{{r.name}}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      
      
      <div slot="footer">
        <Button size="large" @click="modal_save">保存</Button>
        <Button size="large" @click="modal_cancel">关闭</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
let userToken = localStorage.getItem("current_user_token");
function getObjectURL(file) {
  var url = null;
  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}
export default {
  name: "accountCenter",
  data() {
    return {
      profile: process.env.BASE_URL + "img/profile-pic.png",
      user: {
        name: "",
        email: "",
        stat: "",
        reg_time: "",
        role: []
      },
      userOld: {
        name: "",
        email: "",
        stat: "",
        reg_time: "",
        role: []
      },
      password: {
        old: "",
        new1: "",
        new2: ""
      },
      iSettingsShowModal: false,
      statList: [],
      signatureImg: {
        imgObj: null,
        imgUrl: ""
      },
      signatureImgOld: {
        imgObj: null,
        imgUrl: ""
      }
    };
  },
  methods: {
    iSettingsShow() {
      this.iSettingsShowModal = true;
      this.get_data();
    },
    get_data() {
      this.$axios
        .post(this.$url + "/user_info", {
          userToken: userToken,
          askData: "myInfo"
        })
        .then(response => {
          if (response.data.stat === "ok") {
            this.user.name = response.data.info.full_name;
            this.user.email = response.data.info.email;
            this.user.stat = response.data.info.stat;
            this.user.reg_time = response.data.info.reg_time;
            this.user.role = JSON.parse(response.data.info.role);
            this.userOld = JSON.parse(JSON.stringify(this.user));
          } else {
            this.$Message.error("网络连接失败！");
          }
        })
        .catch(error => {
          this.$Message.error(error + "未知错误！");
        });
      /*let config = {
        headers: { responseType: "blob" }
      };
      this.$axios({
        // 用axios发送post请求
        method: "post",
        url: this.$url + "/get_my_info", // 请求地址
        data: {
          userToken: userToken,
          askData: "signature_img"
        }, // 参数
        responseType: "blob" // 表明返回服务器返回的数据类型
      })
        .then(response => {
          this.signatureImg.imgUrl = window.URL.createObjectURL(response.data);
          this.signatureImgOld.imgUrl = this.signatureImg.imgUrl;
        })
        .catch(error => {
          this.$Message.error(error + "未知错误！");
        });*/
    },
    logout: function() {
      localStorage.clear();
      this.logined = false;
      location.href = "/system_login";
    },
    modal_cancel() {
      this.iSettingsShowModal = false;
    },
    modal_save() {
      if (JSON.stringify(this.user) !== JSON.stringify(this.userOld)) {
        this.$axios
          .post(this.$url + "/account_modify", {
            userToken: userToken,
            val: this.user
          })
          .then(response => {
            if (response.data.stat === "ok") {
              this.$Message.success("修改个人信息成功！");
            } else {
              this.$Message.error(response.data.error_info);
            }
          })
          .catch(error => {
            this.$Message.error(error + "未知错误！");
          });
      }
      if (
        this.password.old !== "" &&
        this.password.new1 === this.password.new2 &&
        this.password.new1 !== ""
      ) {
        if (this.password.new1.length < 6 || this.password.new1.length > 19) {
          this.$Message.error("新密码长度应大于6且小于19！");
        } else {
          this.$axios
            .post(this.$url + "/alter_password", {
              userToken: userToken,
              val: this.password
            })
            .then(response => {
              if (response.data.stat === "ok") {
                this.$Message.success("修改密码成功！");
                this.password.old = "";
                this.password.new1 = "";
                this.password.new2 = "";
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch(error => {
              this.$Message.error(error + "未知错误！");
            });
        }
      }
      if (this.signatureImg.imgUrl !== this.signatureImgOld.imgUrl) {
        let form = new FormData();
        form.append("userToken", userToken);
        form.append("signatureImg", this.signatureImg.imgObj);
        let config = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        this.$axios
          .post(this.$url + "/alter_signature_img", form, config)
          .then(response => {
            if (response.data.stat === "ok") {
              this.$Message.success("签名图片修改成功！");
            } else {
              this.$Message.error(response.data);
            }
          })
          .catch(error => {
            this.$Message.error(error + "未知错误！");
          });
      }
    },
    handleSuccess() {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "The file format is incorrect",
        desc:
          "File format of " +
          file.name +
          " is incorrect, please select jpg or png."
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 2M."
      });
    },
    handleBeforeUpload(file) {
      this.signatureImg.imgObj = file;
      let objUrl = getObjectURL(file);
      if (objUrl) {
        this.signatureImg.imgUrl = objUrl;
      }
      return false;
    }
  },
  watch: {},
  computed: {
    pwt: function() {
      return {
        old: this.password.old ? "password" : "text",
        new1: this.password.new1 ? "password" : "text",
        new2: this.password.new2 ? "password" : "text"
      };
    },
    myName:function(){
      let ut = JSON.parse(window.atob(userToken.split('.')[0]));
      return ut.sub;
    }
  }
};
</script>
<style scoped>

.outer-wrapper {
  width: 100%;
  background: #f2f2f2;
}

.inner-wrapper {
  width: 960px;
  margin: 0 auto;
  min-height: 500px;
}

.user-center {
  padding-top: 50px;
  position: relative;
  min-height: 1058px;
}

.user-head {
  margin-top: 150px;
  background-color: #fff;
  height: 380px;
  text-align: center;
}

.user-head a:hover {
  color: #000;
}

.user-head:before {
  content: "";
  position: absolute;
  top: 70px;
  left: 0;
  border-width: 0 480px 130px;
  border-style: solid;
  border-color: transparent transparent #fff;
}

.user-img-container {
  width: 100%;
  position: absolute;
  top: 18px;
}

.member-level-container {
  width: 100%;
  position: absolute;
  top: 96px;
}

.user-phone {
  font-size: 22px;
  position: absolute;
  top: 146px;
  width: 100%;
  text-align: center;
}

.user-action {
  padding: 30px 80px;
}

.user-img {
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.head-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.member-level {
  display: inline-block;
  width: 80px;
  height: 18px;
  line-height: 18px;
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 40px;
  text-align: center;
  color: #fff;
}

.action {
  margin: 0 20px;
  font-size: 15px;
  color: #333;
}

.user-action {
  padding: 50px 80px;
}

.user-action-item {
  margin: 20px 83px;
  width: 100px;
  height: 100px;
}
.user-action-item a {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.desc-text {
  font-size: 15px;
}
.fl {
  float: left;
}
ul {
  list-style: none;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
  text-align: center;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
  min-width: 80px;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
  min-height: 150px;
}

select,
input {
  width: 200px;
  height: 30px;
  border: none;
  outline: none;
  border-bottom: 1px solid #888;
  text-align: center;
}
.info-span {
  color: #000;
}

.role-span {
  border: 1px dashed #999;
  margin: 2px 10px;
  display: inline-block;
}
.img-wrapper {
  width: 242px;
  height: 93px;
  margin: 0 auto;
}
</style>

